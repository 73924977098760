
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { ListDataSource } from "@/data/List/ListDataSource";
import { emailAddres, required } from "@/cms-services/consts";
import { min } from "@/cms-services/consts";
import { Filter } from "@/cms-services/filter";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "client",
  });

  phoneDataSource: ListDataSource = new ListDataSource({
    className: "clientPhone",
    config: {
      filter: JSON.stringify([new Filter("clientId", +this.$route.params.id)]),
    },
  });

  emailDataSource: ListDataSource = new ListDataSource({
    className: "clientEmail",
    config: {
      filter: JSON.stringify([new Filter("clientId", +this.$route.params.id)]),
    },
  });

  phonelModel: any = {
    clientId: +this.$route.params.id,
    caption: "",
    phone: "",
    description: "",
  };

  emailModel: any = {
    clientId: +this.$route.params.id,
    caption: "",
    email: "",
    description: "",
  };

  get caption() {
    return this.dataSource.model?.name;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Клиенты",
        to: "/manage/clients/",
        exact: true,
      },
      {
        text: this.dataSource.model?.name,
        disabled: true,
      },
    ];
  }

  emailFields: any = [
    {
      editor: "string",
      attrs: {
        outlined: true,
        label: "Заголовок",
      },
      name: "caption",
      validations: [required()],
    },
    {
      editor: "string",
      attrs: {
        outlined: true,
        label: "Email",
      },
      name: "email",
      validations: [required(), emailAddres()],
    },
    {
      editor: "memo",
      attrs: {
        outlined: true,
        label: "Описание",
      },
      name: "description",
    },
  ];

  phoneFields: any = [
    {
      editor: "string",
      attrs: {
        outlined: true,
        label: "Заголовок",
      },
      name: "caption",
      validations: [required()],
    },
    {
      editor: "string",
      attrs: {
        label: "Номер телефона",
        outlined: true,
        mask: "+7(###) ###-##-##",
      },
      name: "phone",
      validations: [required(), min(15)],
    },
    {
      editor: "memo",
      attrs: {
        outlined: true,
        label: "Описание",
      },
      name: "description",
    },
  ];
}
